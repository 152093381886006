import React, { useState } from "react";
import './style.css'
import axios from "axios";
import conf from '../../conf'

const OrderItem = (props)=>{

     const { title, prices , lotteryNumber, userName, mobileNumber, whatsappNumber, id, date } = props.ele;
     const [isDelete, setDelete] = useState(false);
     const [isSending, setSending] = useState(false);

     const onDelete = async () =>{
          setSending(true);
          try{
               await axios.delete(conf.serverAddress + "admin/order", {
                    data: {id: id},
                    headers: {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}
               });
               setDelete(true);
          }
          catch(e){
               setSending(false);
          }
     }

     function formatDate(date) {
          var hours = date.getHours();
          var minutes = date.getMinutes();
          var ampm = hours >= 12 ? 'pm' : 'am';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? '0'+minutes : minutes;
          var strTime = hours + ':' + minutes + ' ' + ampm;
          return (date.getMonth()+1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime;
     }

     if(isDelete) return null;
     return(
          <tr>
               <td>{props.id}</td>
               <td>{ userName }</td>
               <td>{ "₹" + prices }</td>
               <td>
                    <label className="badge badge-gradient-success">{lotteryNumber}</label>
               </td>
               <td>{mobileNumber}</td>
               <td>{whatsappNumber}</td>
               <td>{title}</td>
               <td>{formatDate(date)}</td>
               <td><label onClick={onDelete} style={{cursor: isSending ? "wait" : "pointer"}} className="badge badge-gradient-danger">Delete</label></td>
          </tr>
     );
}

export default OrderItem;