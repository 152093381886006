import React, { useState, useEffect } from 'react'
import axios from 'axios';
import conf from '../../conf'

import { DateRangePicker, } from "mui-daterange-picker";



const BlockInactiveUsers = () => {
     const [isSending, setIsSending] = useState(false);

     const [open, setOpen] = React.useState(false);

     const toggle = () => setOpen(!open);


     const [message, setMessage] = useState("* -- Users");
     const [dateRange, setDateRange] = useState({
          startDate: null,
          endDate: null,
     });


     const onSubmit = async ()=>{
          if(!dateRange.endDate || !dateRange.startDate) return;
          setIsSending(true)
          try {
               const rBody = new FormData();
               rBody.append("startDate", dateRange.startDate);
               rBody.append("endDate", dateRange.endDate);
               const res = await axios.post(conf.serverAddress + "admin/block-user-by-range", rBody, 
                    {headers: { 
                         "Content-Type": "multipart/form-data",
                         "Authorization" : `Bearer ${document.cookie.split("=")[1]}`
               }})
               setIsSending(false);
               window.location.reload();
          } catch (error) {
               console.log("error");
               console.log(error);
               setIsSending(false)
          }
     }

     const onChange = async ()=>{
          if(!dateRange.endDate || !dateRange.startDate) return;
          try {
               setMessage("Loading ...");
               const params = new URLSearchParams();
               params.append("startDate", dateRange.startDate);
               params.append("endDate", dateRange.endDate);

               const result = await axios.get(conf.serverAddress + "admin/blocked-user-length", {params: params, headers : {"Authorization" : `Bearer ${document.cookie.split("=")[1]}`}});
               setMessage(`*${result.data.length} Users`)
          } 
          catch (error) {
               setMessage("* -- Users")
          }
     }

     useEffect(onChange, [dateRange]);

     return(
          <>
               <div style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               flexDirection: 'column'
          }}>
               <h3 style={{width: '500px', marginBottom: '20px'}}>Block Inactive Users</h3>
               
               <div style={{width: '500px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                    <div className='time-pecker'>
                         { dateRange.startDate ? new Date(dateRange.startDate).toDateString() : "-- / -- / --" }
                    </div>
                    <div className='time-pecker'>
                         { dateRange.endDate ? new Date(dateRange.endDate).toDateString() : "-- / -- / --" }
                    </div>
               </div>

               
               <DateRangePicker
                    open={open}
                    toggle={toggle}
                    onChange={(range) => setDateRange(range)}
               />

               <span className='select_date' onClick={toggle}>{open ? "Done" : "Select Date"}</span>
               <span className='selected_user_view'>{message}</span>

               <button onClick={onSubmit} className="badge badge-gradient-success"  style={{
                         width: '500px', display: 'flex', justifyContent: 'center',
                         border: 'none', borderRadius: '5px', margin: '15px 0', fontSize: '16px',
                         padding: '10px 0', cursor: isSending ? 'wait' : 'pointer' 
                    }}>Block All Users</button>
          </div>
          </>
     );
}
export default BlockInactiveUsers;