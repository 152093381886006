import React from "react";
import './App.css'
import Deshboard from "./components/dashboard/Deshboard";
import { Routes, Route } from 'react-router-dom'
import Home from "./components/home/Home";
import Notification from "./components/notification/Notification";
import Login from "./components/login/Login";
import Setting from "./components/setting/Setting";
import History from "./components/history/History";
import Order from "./components/order/Order";
import Winner from "./components/winner/Winner";
import AddWinner from "./components/winner/AddWinner";
import Lottery from "./components/lottery/Lottery";
import NewLottery from "./components/lottery/NewLottery";
import LotteryNumber from "./components/lottery/LotteryNumber";
import AddLotteryNumber from "./components/lottery/AddLotteryNumbers";
import LandingPage from "./components/landing_page/LandingPage";
import Result from "./components/result/Result";
import AddResult from "./components/result/AddResult";
import Transaction from "./components/transaction_hist.js/Transaction";
import InactiveUser from "./components/inactive_users/InactiveUser";
import BlockInactiveUsers from "./components/block_inactive_users/BlockInactiveUsers";
import Campaign from "./components/campaign/Campaign";

const App = ()=>{
     const admin = document.cookie.split("=")[0]; 
     console.log();
     if(admin === 'authx'){
          return(
               <>
                    <Routes>
                         <Route path="/" element={<LandingPage/>}/>
                         <Route path="/admin-login" element={<Login/>}/>
                         <Route path="/admin-dashboard" element={<Home><Deshboard/></Home>}/>
                         <Route path="/admin-campaign" element={<Home><Campaign/></Home>}/>
                         <Route path="/admin-inactive-users" element={<Home><InactiveUser/></Home>}/>
                         <Route path="/admin-notification" element={<Home><Notification/></Home>}/>
                         <Route path="/admin-setting" element={<Home><Setting/></Home>}/>
                         <Route path="/admin-history" element={<Home><History/></Home>}/>
                         <Route path="/admin-orders" element={<Home><Order/></Home>}/>
                         <Route path="/admin-winners" element={<Home><Winner/></Home>}/>
                         <Route path="/admin-results" element={<Home><Result/></Home>}/>
                         <Route path="/admin-new-winner" element={<Home><AddWinner/></Home>}/>
                         <Route path="/admin-lotterys" element={<Home><Lottery/></Home>}/>
                         <Route path="/admin-new-lottery" element={<Home><NewLottery/></Home>}/>
                         <Route path="/admin-new-result" element={<Home><AddResult/></Home>}/>
                         <Route path="/admin-lottery-numbers" element={<Home><LotteryNumber/></Home>}/>
                         <Route path="/admin-add-lottery-numbers" element={<Home><AddLotteryNumber/></Home>}/>
                         <Route path="/admin-txns" element={<Home><Transaction/></Home>}/>
                         <Route path="/admin-block-inactive-users" element={<Home><BlockInactiveUsers/></Home>}/>
                         <Route path='*' element={<h1>Page not found</h1>}/>
                    </Routes>
               </>
          );
     }
     else{
          return(
               <>
                    <Routes>
                         <Route path="/" element={<LandingPage/>}/>
                         <Route path="/admin-login" element={<Login/>}/>
                         <Route path="/admin-dashboard" element={<Login/>}/>
                         <Route path="/admin-notification" element={<Login/>}/>
                         <Route path="/admin-setting" element={<Login/>}/>
                         <Route path='*' element={<h1>Page not found</h1>}/>
                    </Routes>
               </>
          );
     }
}

export default App;