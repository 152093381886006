import React from 'react'
import ReactDOM from 'react-dom'
// import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import {ToastProvider} from 'react-toast-notifications';

import "./index.css";

ReactDOM.render(

    <React.StrictMode>
        <ToastProvider placement='bottom-right'>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </ToastProvider>
    </React.StrictMode>
    
, document.getElementById("root"));
